.name p {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 0;
  color: white;
  transform: translate(-50%, -50%);
  background-color: black;
  opacity: 1;
  letter-spacing: 0.05em;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  font-weight: 200;
}

@media (max-width: 640px) {
  .name p {
    font-size: 0.65em;
  }
}

.date p {
  position: absolute;
  text-align: center;
  top: 105%;
  left: 50%;
  color: black;
  transform: translate(-50%, -50%);
  opacity: 1;
  letter-spacing: 0.05em;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  font-weight: 200;
}

@media (max-width: 855px) {
  .date p {
    font-size: 0.8em;
  }
}
