#videos {
  display: flex;
  position: relative;
  width: 90%;
  flex-direction: column;
}

#videos .video {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 500px;
}

#videos .react-player {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#videos .navigation {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 0rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

#videos .navigation::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

#videos .navigation li {
  position: relative;
  list-style: none;
  cursor: pointer;
  margin: 15px 15px;
  padding: 3px 3px 0;
  opacity: 1;
  border-radius: 0px;
  transition: 0.5s;
  background: #fff;
}

@media (max-width: 640px) {
  #videos .navigation li {
    margin: 3px 3px;
    padding: 5px 5px 0;
  }
}

ul {
  padding-inline-start: 0px;
  padding-block-start: 0px;
  padding-block-end: 0px;
}

#videos .navigation li i {
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  background: transparent;
  color: rgba(255, 255, 255, 0.75);
  font-size: 1.2rem;
}

#videos .navigation li:hover i {
  font-size: 1.5rem;
  color: rgba(255, 255, 255);
}

#videos .navigation p {
  font-family: "Poppins", sans-serif;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-weight: normal;
  color: white;
  bottom: -10px;
  font-size: 0.8em;
  background: rgba(0, 0, 0, 0.5);
  width: 95%;
}

#videos .navigation li img {
  width: 120px;
  transition: 0.5s;
}

#videos .navigation li img:hover {
  content: "Lejátszás";
}

@media (min-width: 1150px) {
  #videos .video {
    height: 700px;
  }
}

@media (min-width: 520px) and (max-width: 720px) {
  #videos .video {
    height: 35vh;
  }
}

@media (max-width: 720px) {
  #videos .react-player {
    height: 100% !important;
    width: 100% !important;
    top: 3rem;
  }

  #videos .video {
    height: 25vh;
  }
}

@media (min-width: 1150px) {
  #videos .video {
    height: 700px;
  }
}
