.notfound {
  width: 100% (-1rem);
  height: 93vh;
  background: #efefef;
  margin: 2rem;
  font-family: "Poppins", sans-serif;
}

.notfound .wrapper {
  position: absolute;
  flex-direction: column;
  align-items: center;
  display: flex;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.notfound .wrapper h1 {
  font-size: 2.5em;
}

.notfound .wrapper h2 {
  font-size: 3em;
  margin: 0;
}

.notfound .wrapper p {
  margin-top: 2rem;
  font-size: 1.2em;
}

.notfound .wrapper button {
  margin-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  border: none;
  border-radius: 5px;
  background: transparent;
  text-transform: uppercase;
  transition: all 0.5s ease;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.notfound .wrapper button a {
  text-decoration: none;
  color: black;
  font-weight: 200;
  font-size: 1em;
}

.notfound .wrapper button:hover {
  color: #6d6d6d;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
