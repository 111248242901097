.login {
  width: 100% (-1rem);
  height: 93vh;
  background: #efefef;
  margin: 2rem;
}

.login a {
  position: absolute;
  right: 3rem;
  text-transform: uppercase;
  top: 3rem;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  color: black;
}

.login a:hover {
  color: rgb(80, 80, 80);
}

.login .wrapper {
  position: absolute;
  flex-direction: column;
  align-items: center;
  display: flex;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.wrapper form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper form input {
  width: 250px;
  height: 40px;
  border: 1px solid rgba(60, 64, 67, 0.15);
  padding: 0.5rem;
}

.wrapper form label {
  text-transform: uppercase;
}

.wrapper form input:focus {
  outline: solid 1px #b5a642;
}

.login .wrapper span {
  color: rgba(128, 128, 128, 0.651);
  font-style: italic;
}

.login .wrapper h1 {
  font-size: 2.5em;
}

.login .wrapper h2 {
  text-transform: uppercase;
  font-family: "Bodani Moda", serif;
  font-size: 3em;
  margin: 0;
}

.login .wrapper h2 span {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-size: 0.8em;
  color: black;
  font-style: normal;
}

.login .wrapper p {
  font-size: 1.2em;
  margin: 0;
  padding-bottom: 1rem;
  font-style: italic;
}

.login .wrapper button {
  right: 1.5rem;
  border: 1px solid transparent;
  background: transparent;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.login .wrapper button:hover {
  color: #6d6d6d;
  animation: 4s glowing_login infinite;
}

@keyframes glowing_login {
  0% {
    border-bottom: 1px solid rgb(0, 255, 255);
  }
  33% {
    border-bottom: 1px solid rgb(255, 0, 242);
  }
  66% {
    border-bottom: 1px solid yellow;
  }
  100% {
    border-bottom: 1px solid rgb(0, 255, 255);
  }
}

.errorMessage {
  position: absolute;
  left: 3rem;
  top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.errorMessage p {
  padding: 1rem;
  width: fit-content;
  color: white;
  border-radius: 5px;
  background-color: rgb(196, 22, 22);
}
